import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/modus-react-bootstrap/modus-react-bootstrap/docs/src/layouts/MainLayout.tsx";
import { graphql } from "gatsby";
import { SlidersBasic, SlidersMinMax, SlidersStep } from "../../examples/components/Sliders";
export const query = graphql`
  query SlidersQuery {
    Form: componentMetadata(displayName: { eq: "Form" }) {
      ...ComponentApi_metadata
    }
    FormGroup: componentMetadata(displayName: { eq: "FormGroup" }) {
      ...ComponentApi_metadata
    }
    FormControl: componentMetadata(displayName: { eq: "FormControl" }) {
      ...ComponentApi_metadata
    }
    FormLabel: componentMetadata(displayName: { eq: "FormLabel" }) {
      ...ComponentApi_metadata
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Overview = makeShortcode("Overview");
const CodeBlock = makeShortcode("CodeBlock");
const LinkedHeading = makeShortcode("LinkedHeading");
const ComponentApi = makeShortcode("ComponentApi");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Overview mdxType="Overview">
  Create custom range with{" "}
  <code>&lt;Form.Control type="range" custom /&gt;</code>
  <br />
  <br />
  Only IE and Firefox currently support “filling” in their track from the left of
  the thumb so only those browsers show that styling.
    </Overview>
    <CodeBlock title="Basic range" code={SlidersBasic} mdxType="CodeBlock"></CodeBlock>
    <CodeBlock title="Defining min and max" code={SlidersMinMax} mdxType="CodeBlock">
  Range inputs have implicit values for <code>min</code> and <code>max</code> -
  <code>0</code> and <code>100</code>, respectfully. You may specify new values
  for those using the <code>min</code> and <code>max</code> attributes.
    </CodeBlock>
    <CodeBlock title="Defining steps" code={SlidersStep} mdxType="CodeBlock">
  By default, range inputs “snap” to integer values. To change this, you can
  specify a <code>step</code> value. In the example below, we double the number
  of steps by using <code>step="0.5"</code>.
    </CodeBlock>
    <LinkedHeading h="2" className="h1" id="sliders-api" mdxType="LinkedHeading">
  API
    </LinkedHeading>
    <ComponentApi metadata={props.data.Form} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.FormGroup} exportedBy={props.data.Form} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.FormLabel} exportedBy={props.data.Form} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.FormControl} exportedBy={props.data.Form} mdxType="ComponentApi" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      